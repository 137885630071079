import React from 'react'
import { Col, Dropdown, Row } from 'react-bootstrap';
import cx from './companyProduct.module.scss';
import { dotMenu } from 'src/assets/images';
import CompanyCard from 'src/components/MarketPlace/CompanyCard/CompanyCard';

export default function CompanyProduct() {
    return (
        <div className={`${cx.companyProduct}`}>
            <Row>
                <Col md={12}>
                    <div className={`${cx.productDetails}`}>
                        <h5 className={`${cx.productName}`}>Product</h5>
                        <div className={cx.dotDropdown}>
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic-6">
                                    <img src={dotMenu} className={cx.dots} alt="dot" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#">
                                        <p className={cx.dotDropInside} >
                                            <span>Add Product</span>
                                        </p>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </Col>
                <Col md={12}>
                    <div className={`${cx.serviceRow}`}>
                        <Row>
                            <Col md={4}>
                                <CompanyCard type='Product'/>
                            </Col>
                            <Col md={4}>
                                <CompanyCard type='Product'/>
                            </Col>
                            <Col md={4}>
                                <CompanyCard type='Product'/>
                            </Col>
                            <Col md={4}>
                                <CompanyCard type='Product'/>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
