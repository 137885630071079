import React from 'react'
import { Col, Dropdown, Row } from 'react-bootstrap'
import { dotMenu } from 'src/assets/images';
import cx from './companyEvents.module.scss';
import EventCard from 'src/components/MarketPlace/EventCard/EventCard';
import { Button } from 'src/components/common';
import { MdAdd } from 'react-icons/md';
import { useAppDispatch } from 'src/store/hooks';
import { serviceProviderGlobalModalStateChange } from 'src/store/company/company.slice';
import GlobalServiceProviderModal from 'src/components/MarketPlace/GlobalServiceProviderModal/GlobalServiceProviderModal';

export default function CompanyEvents() {
    const dispatch = useAppDispatch()
    const handleEventsAdd = () => {
        dispatch(serviceProviderGlobalModalStateChange({ state: true, data: null }))
    }
    return (
        <>
            <GlobalServiceProviderModal />
            <div className={`${cx.companyevents}`}>
                <Row>
                    <Col md={12}>
                        <div className={`${cx.eventsDetails}`}>
                            <h5 className={`${cx.eventName}`}>Events</h5>
                            <Button
                                variant="secondary"
                                size="small"
                                className="create-edit-group__buttons__close"
                                onClick={() => handleEventsAdd()}
                            >
                                <MdAdd /> Add Events
                            </Button>
                            {/* <div className={cx.dotDropdown}>
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic-6">
                                    <img src={dotMenu} className={cx.dots} alt="dot" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#">
                                        <p className={cx.dotDropInside} >
                                            <span>Add Events</span>
                                        </p>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div> */}
                        </div>
                    </Col>
                    <Col md={12}>
                        <div className={`${cx.serviceRow}`}>
                            <Row>
                                <Col md={4}>
                                    <EventCard isAction={false} />
                                </Col>
                                <Col md={4}>
                                    <EventCard isAction={false} />
                                </Col>
                                <Col md={4}>
                                    <EventCard isAction={false} />
                                </Col>
                                <Col md={4}>
                                    <EventCard isAction={false} />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}
