import React, { useEffect, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import { Loader, Modal, Typography } from 'src/components/common';
import './globalServiceProviderModal.scss'
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import InputField from 'src/components/common/formFields/inputField/InputField';
import { componentSizeVariantsEnum } from 'src/constants/common.constants';
import Button from 'src/components/button/Button';
import TextArea from 'src/components/common/formFields/textArea/TextArea';
import { Card, Col, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { disablePastDate } from 'src/utils/DisablePastDate/disablePasteDate';
import { serviceProviderGlobalModalStateChange } from 'src/store/company/company.slice';
import { test } from 'src/assets/images';
import { UploadIcon } from 'src/components/common/common.icons';
import { openPopup } from 'src/store/common/common.slice';
import { httpRequest } from 'src/services/commonApis';
import { APIResponse } from 'src/services/base.api.service';
import { TGetCompanyPresignedUrlActionRequest, TUploadCompanyProfilePictureResponse } from 'src/services/apiEndpoint.types.ts';
import { CompanyAPIService } from 'src/services/company.service';
import handleExtension from 'src/utils/handleDocumentNameExtension/handleDocumentNameExtension';

export type TFormValues = {

};

const initialValues: TFormValues = {

};
const globalServiceProviderValidationSchema = []
const GlobalServiceProviderModal = () => {
    const loading: boolean = useAppSelector<boolean>((store: any) => store.common.loading);
    const { serviceProviderCommonModal }: { serviceProviderCommonModal: { state: boolean, data: null | any } } = useAppSelector((store: any) => store.company);
    // states
    const [uploadLoading, setUploadingLoading] = useState<boolean>(false)
    const uploadImageRef = useRef<HTMLInputElement>(null);

    // Hooks
    const dispatch = useAppDispatch();
    const closeModal = () => {
        dispatch(serviceProviderGlobalModalStateChange({ state: false, data: null }))
    };
    function handleSubmit() {

    }
    const openImageFileManager = async () => {
        if (uploadImageRef.current) {
            uploadImageRef.current.click();
        }
    };

    const handleUpload = (e, setFieldValue) => {
        const file = e.target.files[0];
        setUploadingLoading(true)
        if (file) {
            const fileName = handleExtension(file?.name)
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = async () => {
                try {
                    const data = reader.result;
                    const uploadProfilePicturePayload: TGetCompanyPresignedUrlActionRequest = {
                        // id: viewCompany?.id,
                        id: '',
                        data,
                        showPopup: false
                    };
                    const response = await httpRequest(`program/banner/upload?companyId=${uploadProfilePicturePayload.id}&fileName=${fileName}`, 'GET', null, 'json', 'program');
                    if (response?.data?.signedUrl) {
                        const res: APIResponse<TUploadCompanyProfilePictureResponse> | any =
                            await new CompanyAPIService().uploadCompanyProfilePicture({
                                presignedUrl: response?.data?.signedUrl,
                                data,
                                includeAuthorizationHeaders: false
                            });
                        if (res.status === 200) {
                            const data = await httpRequest(`program/banner/upload?companyId=${uploadProfilePicturePayload.id}&fileName=${fileName}`, 'GET', null, 'json', 'program');
                            if (data?.data?.key) {
                                setFieldValue('banner', { url: data?.data?.key })
                            } else {
                                dispatch(
                                    openPopup({
                                        popupMessage: 'something went wrong',
                                        popupType: 'error'
                                    })
                                );
                            }
                            setUploadingLoading(false)
                        } else {
                            dispatch(
                                openPopup({
                                    popupMessage: res?.message || 'something went wrong',
                                    popupType: 'error'
                                })
                            );
                        }
                    } else {
                        setUploadingLoading(false)
                        dispatch(
                            openPopup({
                                popupMessage: 'something went wrong',
                                popupType: 'error'
                            })
                        );
                    }
                } catch (error) {
                    setUploadingLoading(false)
                    dispatch(
                        openPopup({
                            popupMessage: error?.message || 'something went wrong',
                            popupType: 'error'
                        })
                    );
                }
            }
        }
    }

    return (
        <Modal
            isModalOpen={serviceProviderCommonModal.state}
            onModalClose={closeModal}
            title="Add Event"
            className="add__global-modal"
        >
            <div className="add__global">
                {/* Form starts */}
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={globalServiceProviderValidationSchema}
                    enableReinitialize
                >
                    {({ setFieldValue, values, errors, touched }) => {
                        return (
                            <Form className="add__global__form" noValidate>
                                <div className='add__global__form__crate-form'>
                                    <Row>
                                        <Col md={12}>
                                            <div className="program-details__form__row">
                                                <div className='mb-3 w-100'>
                                                    {<>
                                                        <InputField
                                                            label="Upload Images"
                                                            id='banner-label'
                                                            hidden
                                                            variant={componentSizeVariantsEnum.SMALL}
                                                            placeholder="Upload Images"
                                                            type="text"
                                                        />

                                                        <div
                                                            className="dynamic-field__upload-file"

                                                        >
                                                            <span className="dynamic-field__upload-file__drag-drop" onClick={openImageFileManager}>
                                                                <UploadIcon />
                                                                <input
                                                                    type="file"
                                                                    hidden
                                                                    value={''}
                                                                    ref={uploadImageRef}
                                                                    accept=".jpg,.jpeg,.png,"
                                                                    className="select-post-image"
                                                                    onChange={(e) => {
                                                                        handleUpload(e, setFieldValue)
                                                                    }}
                                                                // onChange={(e) => {
                                                                //   handleUploadMedia({ e, onLoad: onMediaLoad, mediaType: 'program' });
                                                                // }}
                                                                />
                                                            </span>
                                                            <Typography
                                                                as="span"
                                                                variant="body-2"
                                                                fontWeight="medium"
                                                                className="dynamic-field__upload-file__browse"
                                                                onClick={openImageFileManager}
                                                            >
                                                                Upload Images
                                                            </Typography>

                                                        </div>
                                                    </>}
                                                    {/* {false && <Loader />} */}

                                                    {/* {<Row>
                                                        <Col md={12}>
                                                            <div className='position-relative w-100'>
                                                                <button style={{ position: 'absolute', top: '10px', right: '10px', background: '#fff', height: '23px', fontSize: '14px', width: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%', border: 'none', color: 'red' }} onClick={() => setFieldValue('banner', { url: '' })}>
                                                                    X
                                                                </button>
                                                                <img src={test} alt='banner' style={{ width: '100%', height: '150px', objectFit: 'cover', backgroundPosition: 'center', marginTop: '10px', borderRadius: '10px' }} />
                                                            </div>
                                                        </Col>
                                                    </Row>} */}

                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <InputField
                                                required
                                                className='mb-3'
                                                label="Event Title"
                                                id="cohortTitle"
                                                variant={componentSizeVariantsEnum.SMALL}
                                                placeholder="Event Title"
                                                type="text"
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <InputField
                                                required
                                                label="Custom button text"
                                                id="cohortTitle"
                                                variant={componentSizeVariantsEnum.SMALL}
                                                placeholder="Apply here"
                                                type="text"
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <InputField
                                                required
                                                label="Date"
                                                min={disablePastDate()}
                                                id="date"
                                                variant={componentSizeVariantsEnum.SMALL}
                                                placeholder=""
                                                type="date"
                                            />
                                        </Col>
                                        <Col md={12}>
                                            <InputField
                                                label="Link"
                                                className='mb-3 mt-3'
                                                id="cohortTitle"
                                                variant={componentSizeVariantsEnum.SMALL}
                                                placeholder="www.ProgramApplicationFormLink.com"
                                                type="text"
                                            />
                                        </Col>
                                        <Col md={12}>
                                            <h5 className='typography__caption typography__semiBold mb-2'>Description</h5>
                                            <TextArea id='programObjective' placeholder='Description'
                                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setFieldValue('programObjective', e.target.value)}
                                                textAreaStyle={{ width: '100%' }}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="add__global__form__buttons">
                                    <Button variant="secondary" type="button" size="small" onClick={closeModal}>
                                        Cancel
                                    </Button>

                                    <Button variant="primary" type="submit" size="small" loading={loading} >
                                        Post Events
                                    </Button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik >
            </div >
        </Modal >
    );
};

export default GlobalServiceProviderModal;
