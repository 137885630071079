import React from 'react';
import cx from './companyServices.module.scss';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { dotMenu } from 'src/assets/images';
import ServiceSubCategoryCard from 'src/components/ServiceProvider/ServiceSubCategoryCard/ServiceSubCategoryCard';
import { Button } from 'src/components/common';
import { MdAdd } from 'react-icons/md';

export default function CompanyServices() {
    return (
        <div className={`${cx.companyServices}`}>
            <Row>
                <Col md={12}>
                    <div className={`${cx.serviceDetails}`}>
                        <h5 className={`${cx.serviceName}`}>Service 1</h5>
                        <div className={cx.dotDropdown}>
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic-6">
                                    <img src={dotMenu} className={cx.dots} alt="dot" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#">
                                        <p className={cx.dotDropInside} >
                                            <span>Add Services</span>
                                        </p>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </Col>
                <Col md={12}>
                    <div className={`${cx.serviceRow}`}>
                        <Row>
                            <Col md={4}>
                                <ServiceSubCategoryCard />
                            </Col>
                            <Col md={4}>
                                <ServiceSubCategoryCard />
                            </Col>
                            <Col md={4}>
                                <ServiceSubCategoryCard />
                            </Col>
                            <Col md={4}>
                                <ServiceSubCategoryCard />
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <div className={`${cx.serviceDetails}`}>
                        <h5 className={`${cx.serviceName}`}>Service 1</h5>
                        <div className={cx.dotDropdown}>
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic-6">
                                    <img src={dotMenu} className={cx.dots} alt="dot" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#">
                                        <p className={cx.dotDropInside} >
                                            <span>Add Services</span>
                                        </p>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </Col>
                <Col md={12}>
                    <div className={`${cx.serviceRow}`}>
                        <Row>
                            <Col md={4}>
                                <ServiceSubCategoryCard />
                            </Col>
                            <Col md={4}>
                                <ServiceSubCategoryCard />
                            </Col>
                            <Col md={4}>
                                <ServiceSubCategoryCard />
                            </Col>
                            <Col md={4}>
                                <ServiceSubCategoryCard />
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col md={12}>
                    <div className={`${cx.addServices}`}>
                        <Button
                            variant="secondary"
                            size="small"
                            className="create-edit-group__buttons__close"
                        >
                           <MdAdd /> Add Services
                        </Button>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
