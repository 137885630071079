import React from 'react'
import { Col } from 'react-bootstrap';
import cx from './serviceSubCategoryCard.module.scss';
import { EditIcon, TrashIcon } from 'src/components/common/common.icons';
import TextArea from 'src/components/common/formFields/textArea/TextArea';

export default function ServiceSubCategoryCard() {
    return (
        <div className={`${cx.subCategoryCard}`}>
            <div className={`${cx.header}`}>
                <h5>android app</h5>
                <div className={`${cx.actions}`}>
                    <EditIcon className={cx.icons} />
                    <TrashIcon />
                </div>
            </div>
            <div className={`${cx.content}`}>
                <TextArea textAreaStyle={{ width: '100%' }} disabled={true}/>
            </div>
        </div>
    )
}
