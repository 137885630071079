import { TNavigation } from 'src/components/navigation/Navigation';
import { BulbIcon, ComingSoonIcon, HomeIcon, NoteIcon } from 'src/components/common/common.icons';
import ChatIcon from 'src/components/common/common.icons/ChatIcon';
import { useAppSelector } from 'src/store/hooks';
import { useMemo } from 'react';
import MarketplaceIcon from 'src/components/common/common.icons/MarketplaceIcon';
import CohortIcon from 'src/components/common/common.icons/CohortIcon';

const useSideNavItems = () => {
  // Hooks
  const { ideaHubNotificationCount, totalUnreadConversationCount } = useAppSelector(
    (store) => store.notifications
  );

  const SIDE_NAV_ITEMS: TNavigation[] = useMemo(() => {
    return [
      {
        url: '/dashboard',
        key: 'dashboard',
        startIcon: <HomeIcon />,
        title: 'Home',
        state: 'default',
        notificationCount: 0,
        availableToUserType: 'All'
      },
      {
        url: '/idea-hub',
        key: 'idea-hub',
        startIcon: <BulbIcon />,
        title: 'Idea hub',
        state: 'default',
        notificationCount: ideaHubNotificationCount,
        availableToUserType: 'User'
      },
      {
        url: '/resources',
        key: 'resources',
        startIcon: <NoteIcon />,
        endIcon: <ComingSoonIcon className="no-hover-state" />,
        title: 'Resources',
        state: 'default',
        notificationCount: 0,
        availableToUserType: 'User'
      },
      {
        url: '/chat',
        key: 'chat',
        startIcon: <ChatIcon />,
        title: 'Chat',
        state: 'default',
        notificationCount: totalUnreadConversationCount,
        availableToUserType: 'All'
      },
      {
        availableToUserType: 'User',
        key: 'cohort',
        notificationCount: 0,
        startIcon: <CohortIcon />,
        state: 'default',
        title: 'Cohort',
        url: `/cohort`
      },
      {
        availableToUserType: 'User',
        key: 'marketplace',
        notificationCount: 0,
        startIcon: <MarketplaceIcon />,
        state: 'default',
        title: 'MarketPlace',
        url: `/marketplace`
      }
    ];
  }, [ideaHubNotificationCount, totalUnreadConversationCount]);

  return SIDE_NAV_ITEMS;
};

export default useSideNavItems;
